@use "sass:math";

@mixin box-sizing($box-model) {
	-webkit-box-sizing: $box-model;
	-moz-box-sizing: $box-model;
	box-sizing: $box-model;
}

@mixin box-shadow($shadow1, $shadow2: false) {
	$params: $shadow1;

  	@if $shadow2 {
		$params: $shadow1, $shadow2;
	}

  	-webkit-box-shadow: $params;
   -moz-box-shadow: $params;
   box-shadow: $params;
}

@mixin rounded($radius: 100%, $upperRight: null, $lowerLeft: null, $lowerRight: null) {
   @if $upperRight != null {
      -webkit-border-top-left-radius: $radius;
      border-top-left-radius: $radius;
      -webkit-border-top-right-radius: $upperRight;
      border-top-right-radius: $upperRight;
   }

   @if $lowerLeft != null {
      -webkit-border-bottom-left-radius: $lowerLeft;
      border-bottom-left-radius: $lowerLeft;
   }

   @if $lowerRight != null {
      -webkit-border-bottom-right-radius: $lowerRight;
      border-bottom-right-radius: $lowerRight;
   }

   @if $radius != null and $upperRight == null and $lowerLeft == null and $lowerRight == null {
      -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      border-radius: $radius;
   }
}

@mixin innerRounded($radius) {
	-webkit-border-radius: math.div($radius, 2);
  	-moz-border-radius: math.div($radius, 2);
  	border-radius: math.div($radius, 2);
}

@mixin outerRounded($radius) {
	-webkit-border-radius: $radius * 2;
  	-moz-border-radius: $radius * 2;
  	border-radius: $radius * 2;
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
   content: $content;
   display: $display;
   position: $pos;
}

@mixin square($pixels) {
	width: $pixels;
	height: $pixels;
}

@mixin selection {
	& ::selection {@content;}
	& ::-moz-selection {@content;}
}

@mixin ellipsis() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin transform($value) {
	-webkit-transform: $value;
	-moz-transform: $value;
   -ms-transform: $value;
	-o-transform: $value;
	transform: $value;
}

@mixin transition($value, $value2: "empty") {
	@if ($value2 != "empty") {
		-webkit-transition: $value, $value2;
		-moz-transition: $value, $value2;
		-ms-transition: $value, $value2;
		-o-transition: $value, $value2;
		transition: $value, $value2;
	} @else {
		-webkit-transition: $value;
		-moz-transition: $value;
		-ms-transition: $value;
		-o-transition: $value;
		transition: $value;
	}
}

@mixin animation($animationName) {
	-webkit-animation: $animationName;
	-moz-animation: $animationName;
	-o-animation: $animationName;
	animation: $animationName;
}

@mixin smooth-ease($time: .2s) {
	-webkit-transition: all $time ease-in-out;
	-moz-transition: all $time ease-in-out;
	-ms-transition: all $time ease-in-out;
	-o-transition: all $time ease-in-out;
	transition: all $time ease-in-out;
}

@mixin cubic($time) {
	-webkit-transition: all $time cubic-bezier(.694, .0482, .335, 1);
	-moz-transition: all $time cubic-bezier(.694, .0482, .335, 1);
	-ms-transition: all $time cubic-bezier(.694, .0482, .335, 1);
	-o-transition: all $time cubic-bezier(.694, .0482, .335, 1);
	transition: all $time cubic-bezier(.694, .0482, .335, 1);
}

@mixin filter($value) {
   -webkit-filter: $value;
   -moz-filter: $value;
   -o-filter: $value;
   filter: $value;
}

@mixin bottomTopLinearGradient($bottomColor: black, $topColor: transparent, $bottomPercent: 10%, $topPercent: 90%) {
	background: $bottomColor; // fallback
	background: -moz-linear-gradient(0deg, $bottomColor $bottomPercent, $topColor $topPercent);
	background: -webkit-linear-gradient(0deg, $bottomColor $bottomPercent, $topColor $topPercent);
	background: linear-gradient(0deg, $bottomColor $bottomPercent, $topColor $topPercent);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$topColor,endColorstr=$topColor,GradientType=1);
}

@mixin blend($color, $mode: multiply) {
	background-color: $color;
   background-blend-mode: $mode;
}

@mixin keyframes($animationName) {
   @-webkit-keyframes #{$animationName} {
      @content;
   }
   @-moz-keyframes #{$animationName} {
      @content;
   }
   @-o-keyframes #{$animationName} {
      @content;
   }
   @keyframes #{$animationName} {
      @content;
   }
}

@mixin grid($col: 2, $gap: 16px) {
	display: grid;
	grid-template-columns: repeat($col, 1fr);
	grid-gap: $gap;
}

@mixin image-fit($value: cover) {
	object-fit: $value;
	width: 100%;
	height: 100%;
}

@mixin input-outline() {
   box-shadow: 0 0 0 3px rgba(100, 100, 100, 0.25) inset;
   outline: 0;
}

@mixin button-outline() {
   box-shadow: 0 0 0 3px var(--box-shadow-outline);
   outline: 0;
}