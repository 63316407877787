
















































@import '~@/variables';
@import '~@/functions';

$alert-message-height: 20px;
$alert-box-height: 60px;

#alert {
   position: fixed;
   z-index: 1000;
   left: 0;
   right: 0;
   background: $main-informational-color-light;
   padding: $medium-gap $large-gap;
   transition: .25s all ease-in-out;
   height: $alert-box-height;
   bottom: -$alert-box-height;
   border-top: $small-gap solid rgba(0, 0, 0, 0.25);

   @media screen and (max-width: $breakpoint-mobile) {
      padding: $medium-gap;
   }

   &.warning {
      background: $main-danger-color-light;
   }

   &.error {
      background: $main-error-color-light;
   }

   &.success {
      background: $main-success-color-light;
   }

   &.notactive {
      bottom: -$alert-box-height;
      opacity: .8;
   }

   &.startactive {
      bottom: 0;
      opacity: 1;
   }

   &.keepactive {
      opacity: 1;
      bottom: 0;
   }

   #alert-message {
      display: grid;
      grid-template-columns: $alert-message-height 1fr;
      grid-column-gap: $medium-gap;
      height: $alert-message-height;
      line-height: $alert-message-height;
      color: $main-color;

      i.fa-icon {
         font-size: 18px;
         color: $main-color;
      }

      span {
         @include ellipsis();
      }
   }
}
