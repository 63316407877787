
// Colors
$background-color: #f7f7f7;
// #2d2d2d
$footer-color: rgba(255, 255, 255, 0.75);
$main-color: #333;
// #9d9d9d
$onwhite-faded-color: rgba(0, 0, 0, 0.35);
$footer-link-color: #95b1d5;
$main-accent-color-washed: #e5ecf4;
// #171717
$main-accent-color-lighter: #1a529a;
$main-accent-color: #0e273f;
$main-accent-color-darker: #0b1c2b;
$white-color: #fff;
// #202020
$black-color: #000;
$gray-color: #777;
$main-white-color: #f7f7f7;
$main-gray-color: #efefef;

$main-danger-color-light: rgb(255, 188, 188);
$main-danger-color-shade: #600101;
$main-danger-color: #8a0000;
$main-danger-color-darker: #8c4242;
$main-informational-color-light: rgb(203, 220, 251);
$main-error-color-shade: #f4da00;
$main-error-color: #ffee5f;
$main-error-color-light: #fff17b;
$main-success-color-shade: #27662a;
$main-success-color: #3d9841;
$main-success-color-light: rgb(181, 243, 192);

$main-box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.15);
$main-bold-weight: bold;
$main-standard-weight: 400;
$main-font-family: 'Montserrat', sans-serif;
$main-button-rounding: 4px;
$thin-light-border: 1px solid rgba(0, 0, 0, 0.05);
$thin-light-border-2px: 2px solid rgba(0, 0, 0, 0.05);

// Font sizes
$body-font-size: 15.1px;
$smaller-font-size: 13px;

// Padding
$large-gap: 30px;
$medium-gap: 16px;
$small-gap: 8px;
$micro-gap: 4px;

// Logo sizes
$logo-width: 64px;
$logo-height: 90px;
$logo-collapsed-width: 39px;
$logo-collapsed-height: 55px;

$input-height: 44px;
$sidebar-size: 280px;
$sidebar-size-minimized: 230px;
$header-topbar-height: 35px;
$header-main-inner-height: 55px;

$header-main-height: calc(#{$header-main-inner-height} + #{$medium-gap});
$header-total-height: calc(#{$header-topbar-height} + #{$header-main-height});

$breakpoint-small-mobile: 425px;
$breakpoint-mobile: 650px;
$breakpoint-pablet: 800px;
$breakpoint-tablet: 1100px;
$breakpoint-laptop: 1300px;
