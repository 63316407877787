











































































@import '~@/variables';
@import '~@/functions';

.prop-logo {
   width: $logo-width;
   height: $logo-height;
   background-size: cover !important;
   margin: auto;
   @include smooth-ease();
}

#footer-logo {
   .prop-logo {
      opacity: .25;
   }
}

main {
   &.transparent-header {
      header.top-of-page {
         .prop-logo {
            background-size: contain;
         }
      }
   }

   header {
      &.collapsing, &.collapsed {
         .prop-logo {
            height: $logo-collapsed-height;
            width: $logo-collapsed-width;
         }
      }

      @media screen and (max-width: $breakpoint-small-mobile) {
         .prop-logo {
            height: $logo-collapsed-height;
            width: $logo-collapsed-width;
         }
      }
   }
}
