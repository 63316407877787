




























































































































































































































































@import '~@/variables';
@import '~@/functions';

#cookie-wrapper {
   padding: $medium-gap;
   background: #0b1c2b;
   max-width: 350px;
   box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
   border: 1px solid rgba(0, 0, 0, 0.05);
   border-radius: 0;

   .cookie__floating__wrap {
      .cookie__floating__content {
         max-height: 145px;
      }

      .cookie__floating__buttons {
         @include grid();

         .cookie__floating__buttons__button {
            @include rounded($main-button-rounding);
            padding: 10px 20px;
            background: $main-accent-color-lighter;
            color: $main-white-color;
            box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.25) inset, 0 1px 0 rgba(0, 0, 0, 0.65);
            border: none;

            &.cookie__floating__buttons__button--accept {
               background: $main-success-color;
            }

            &.cookie__floating__buttons__button--decline {
               background: $main-danger-color;
            }
         }
      }
   }
}
