@import 'variables';
@import 'functions';
@import 'snippets';

:root {
   --background-color: #f7f7f7;
   --background-accent-color: #fff;
   --background-accent-color-alt: #e5ecf4;
   --input-background-color: #efefef;
   --input-background-color-alt: #e2e2e2;
   --font-color: #333;
   --link-color: #1a529a;
   --header-font-color: #000;
   --thin-border: 1px solid rgba(0, 0, 0, 0.1);
   --standout-border: 2px dashed rgba(0, 0, 0, 0.15);
   --accent-color: #0e273f;
   --header-hover-color: rgba(255, 255, 255, 0.5);
   --header-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1);
   --light-hover-color: rgba(0, 0, 0, 0.1);
   --danger-color: #8a0000; // used for required
   --section-box-shadow: 2px 2px 0 #dedede;
   --section-border: 1px solid rgba(0, 0, 0, 0.1);
   --box-shadow-outline: rgba(100, 100, 100, 0.25);
}

[data-theme="dark"] {
   --background-color: #2d2d2d;
   --background-accent-color: #202020;
   --background-accent-color-alt: #171717;
   --input-background-color: #373737;
   --input-background-color-alt: #606060;
   --font-color: #9d9d9d;
   --link-color: #5993df;
   --header-font-color: #c4c4c4;
   --thin-border: 1px solid rgba(255, 255, 255, 0.1);
   --standout-border: 2px dashed rgba(255, 255, 255, 0.15);
   --accent-color: #556e88;
   --header-hover-color: rgba(255, 255, 255, 0.1);
   --header-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.4);
   --light-hover-color: rgba(255, 255, 255, 0.1);
   --section-box-shadow: 2px 2px 0 #1b1b1b;
   --section-border: 1px solid rgba(0, 0, 0, 0.1);
   --box-shadow-outline: rgba(200, 200, 200, 0.25);
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   -webkit-appearance: none;
   -moz-appearance: none;
}

html {
   overflow-x: hidden;

   &.no-scroll {
      overflow-y: hidden;
   }
}

body {
   font-family: $main-font-family;
   font-weight: $main-standard-weight;
   font-size: $body-font-size;
   line-height: 1.4;
   -webkit-font-smoothing: antialiased;
   -webkit-text-size-adjust: 100%;
   -ms-text-size-adjust: 100%;
   text-rendering: optimizeLegibility;

   u {
      text-decoration-style: double;
   }

   main {
      background: var(--background-color);
      color: var(--font-color);
      display: flex;
      min-height: 100vh;
      flex-direction: column;
   }

   a {
      outline: none;
      color: var(--link-color);
      text-decoration: underline;
      cursor: pointer;
      @include transition(color .25s ease-in-out);

      &:hover, &:focus {
         color: $footer-link-color;
      }

      &:focus, &.router-link-exact-active {
         font-weight: bold;
      }

      &[rel="noreferrer noopener"] {
         position: relative;

         &:before {
            color: var(--link-color);
            content: '\f08e';
            font-family: 'Font Awesome 5 Pro';
            font-weight: 300;
            right: -6px;
            top: -4px;
            font-size: 8px;
         }
      }
   }

   b, strong {
      color: var(--header-font-color);
   }

   img {
      width: 100%;
   }

   table {
      border-spacing: 0;
      width: 100%;

      &.no-style {

         tr {
            background: transparent !important;

            td, th {
               padding: 0;

               &:first-of-type {
                  border-left: unset;
               }
            }
         }
      }

      tr {
         td {
            padding: $small-gap;
         }

         /*td, th {
            &:first-of-type {
               border-left: 5px solid transparent;
            }
         }*/

         th {
            padding: $small-gap;
            text-align: left;
            border-bottom: $thin-light-border;
         }

         &:nth-child(even) {
            background: #00000005;
         }

         /*
         &.row-type {
            td:first-of-type {
               border-left: 5px solid $main-gray-color;
            }

            &.success td:first-of-type {
               border-color: $main-success-color-light;
            }

            &.danger td:first-of-type {
               border-color: $main-danger-color-light;
            }

            &.info td:first-of-type {
               border-color: $main-informational-color-light;
            }
         }*/
      }
   }

   ul, ol {
      padding: 0;
      margin: 0;
   }

   li {
      display: block;
   }

   #property-quick-highlights,
   #property-description {
      ul, ol {
         padding: 0 0 0 20px;

         li {
            display: list-item;
         }
      }
   }

   ul.split {
      @include grid();

      label {
         display: inline-block;
      }

      @media screen and (max-width: $breakpoint-mobile) {
         grid-template-columns: 1fr;
         grid-gap: $medium-gap;
      }
   }

   nav {
      li {
         display: inline-block;
      }
   }

   h1, h2, h3, h4, h5, h6 {
      margin: 0 0 10px;
      color: var(--header-font-color);
   }

   h1 {
      position: relative;
      margin-bottom: 25px;
      padding-bottom: 10px;

      &:after {
         border-bottom: 4px solid var(--font-color);
         width: 75px;
         bottom: 0;
         left: 0;
         opacity: .2;
      }
   }

   p {
      margin: 0 0 $medium-gap;
   }

   fieldset {
      padding: 40px 20px 20px;
      border: none;
      background: var(--background-accent-color);
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;

      legend {
         position: absolute;
         top: 5px;
         right: 15px;
         opacity: .25;
         font-size: 22px;
         color: $main-accent-color;
      }
   }

   hr {
      margin: $medium-gap 0;
      border: var(--thin-border);
      border-width: 1px 0 0;

      &.decoration {
         background: rgba(0, 0, 0, 0.15);
         width: 75px;
         height: 4px;
         border: none;

         &.light {
            background: rgba(255, 255, 255, 0.15);
         }
      }
   }

   footer, header {
      flex: none;
   }

   .fa-icon {
      color: var(--link-color);
      text-align: left;
   }

   .input {
      .fa-icon {
         @include square(44px);
         position: absolute;
         padding: 10px;
         color: var(--link-color);
         font-size: 24px;
         z-index: 1;
      }
   }

   input {
      @include input-styling();

      &[type="radio"], &[type="checkbox"] {
         display: inline-block;
         width: initial;
      }

      &:hover {
         border-color: var(--link-color);
      }

      &:focus {
         border-color: var(--accent-color);
      }
   }

   button {
      @include smooth-ease(.15s);
      font-family: $main-font-family;
      font-size: $body-font-size;
      border: none;
      cursor: pointer;
      background: none;

      &[disabled] {
         cursor: not-allowed;
      }
   }

   :after,:before {
      content: '';
      position: absolute;
   }

   ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
     color: $gray-color;
     opacity: 1; /* Firefox */
   }

   :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: $gray-color;
   }

   ::-ms-input-placeholder { /* Microsoft Edge */
     color: $gray-color;
   }
}

.grecaptcha-badge {
   bottom: 96px !important;
}

.sticky-submit {
   position: sticky;
   bottom: 0;
   z-index: 100;
}

.clear-formation {
   background: none !important;
   color: inherit !important;

   * {
      background: none !important;
      color: inherit !important;
   }

   br {
      display: none;
   }
}

.arrow {
   display: inline-block;
   height: 5px;
   width: 10px;
   transform: rotate(0deg);
   margin: 2px 4px;
   transition: .2s all ease-in-out;

   &.opened {
      transform: rotate(180deg);
   }

   span {
      height: 5px;
      width: 10px;
      border: 5px solid transparent;
      border-color: var(--header-font-color) transparent transparent transparent;
      position: absolute;
   }
}

#site-content {
   margin: $header-total-height 0 0;
   flex: 1 0 auto;
}

.video-js {
   width: 100%;
}

.screen-reader {
   position: absolute;
   white-space: nowrap;
   width: 1px;
   height: 1px;
   overflow: hidden;
   border: 0;
   padding: 0;
   clip: rect(0 0 0 0);
   clip-path: inset(50%);
   margin: -1px;
}

.asterisk {
   &:after, &:before {
      position: relative;
   }

   &.after {
      &:after {
         content: "*";
      }

      &.double:after {
         content: "**";
      }
   }

   &.before {
      &:before {
         content: "*";
      }

      &.double:before {
         content: "**";
      }
   }
}

.no-padding {
   padding: 0 !important;
}

.no-margin {
   margin: 0 !important;
}

.static-page-content {
   p {
      padding: 0 0 $small-gap;
      margin: 0;

      b, strong {
         color: var(--header-font-color);
      }
   }

   ul, ol {
      padding-left: $large-gap;

      &>li {
         display: list-item;
         margin-bottom: $small-gap;

         &:last-of-type {
            margin: 0;
         }
      }
   }
}

.clear-display {
    padding-left: $large-gap;
    &> ul, &> ol {
        padding-left: $large-gap;
    }
    &>li,  &>ol li, &>ul li {
        display: list-item;
        margin-bottom: $small-gap;

        &:last-of-type {
            margin: 0;
        }
    }
}

.page-padding {
   padding-top: $large-gap;

   @media screen and (max-width: $breakpoint-laptop) {
      padding-top: $medium-gap;
   }

   @media screen and (max-width: $breakpoint-small-mobile) {
      padding-top: $small-gap;
   }
}

.center-text {
   text-align: center;

   a {
      text-transform: capitalize;
   }
}

.centering {
   display: table;
   position: absolute;
   height: 100%;
   width: 100%;
   margin: auto;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;

	> div {
		display: table-cell;
      vertical-align: middle;

		> div {
			margin: auto;
		}
	}
}

.overlay {
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   background: $black-color;
   z-index: 1;
   opacity: .5;
   @include smooth-ease();

   &.tint {
      background: $main-accent-color;
   }

   &.dimmer {
      opacity: .75;
   }
}

.classic-button {
   @include rounded($main-button-rounding);
   padding: 10px 20px;
   background: $main-accent-color-lighter;
   color: $main-white-color;
   box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.25) inset;
   border: solid rgba(0, 0, 0, 0.55);
   border-width: 0 1px 1px 0;

   &:hover {
      background: $main-accent-color;
   }

   &.danger {
      background: $main-danger-color;

      &:hover {
         background: $main-danger-color-shade;
      }
   }

   &.success {
      background: $main-success-color;

      &:hover {
         background: $main-success-color-shade;
      }
   }

   &.yellow {
      background: $main-error-color;

      &:hover {
         background: $main-error-color-shade;
      }

      i.fa-icon {
         color: $black-color;
      }
   }

   &:focus {
      @include button-outline();
      border-color: rgba(0, 0, 0, 0.15);
   }

   &.disabled, &[disabled] {
      background: $gray-color;
      cursor: not-allowed;
   }

   &.small-size {
      @include rounded(3px);
      font-size: 13px;
      padding: 8px 11px;
   }

   &.slim {
      @include square(40px);
      padding: 12px;

      i.fa-icon {
         @include square(15px);
         font-size: 18px;
         line-height: 15px;
      }
   }

   i.fa-icon {
      color: $main-white-color;
   }
}

.full-width {
   width: 100%;
}

.button-container {
   @media screen and (max-width: $breakpoint-mobile) {
      button {
         width: 100%;
      }
   }
}

.form-notice {
   text-align: center;
   line-height: 30px;

   @media screen and (max-width: $breakpoint-mobile) {
      text-align: left;
   }
}

.hidden {
   display: none !important;
}

.label {
   font-size: $smaller-font-size;
   display: block;
   font-weight: $main-bold-weight;
   cursor: text;
   margin-bottom: 5px;
   color: var(--header-font-color);
}

.required:after {
   content: "*";
   color: $main-danger-color;
   margin-left: 2px;
}

.highlight-link {
   @include rounded($main-button-rounding);
   @include smooth-ease(.15s);
   background: rgba(255, 255, 255, 0.65);
   color: $main-accent-color-darker;
   padding: 2px 5px;
   margin: 0 2px;
   text-decoration: none;
   font-weight: 400;

   &:hover {
      background: $main-white-color;
   }
}

.vertical-rule {
   margin: 0 $medium-gap;
   border-left: 1px solid $main-white-color;
   opacity: .25;
}

.container {
   margin: auto;
   width: 1200px;
   padding: 0 $medium-gap;
   position: relative;
   @include transition(width .25s ease-in-out .125s);

   &.focused {
      max-width: 650px;
      background:  var(--background-accent-color);
      padding: $large-gap;
      box-shadow: var(--section-box-shadow);
      border: var(--section-border);
   }

   @media screen and (max-width: $breakpoint-laptop) {
      width: 1000px;
   }

   @media screen and (max-width: $breakpoint-tablet) {
      width: 770px;
   }

   @media screen and (max-width: $breakpoint-pablet) {
      width: 100%;
   }

   @media screen and (max-width: $breakpoint-small-mobile) {
      padding: 0 $small-gap;
   }
}
#header {
   .page-sidebar {
      position: sticky;
      top: calc(#{$header-total-height} + #{$large-gap});
   }

   &.collapsed {
      .page-sidebar {
         top: calc(#{$header-main-height} + #{$large-gap});
      }
   }
}

.sectioned {
   background: var(--background-accent-color);
   box-shadow: var(--section-box-shadow);
   border: var(--section-border);
   border-width: 1px 0 0 1px;
   padding: $medium-gap;

   .sectioned {
      background: var(--background-accent-color-alt);
      box-shadow: none;
      border-width: 1px;
   }

   .form-field:last-child {
      margin: 0;
   }
}

.info-box {
   @include rounded($main-button-rounding);
   border: 1px solid rgba(0, 0, 0, 0.1);
   display: inline-block;
   padding: 5px 10px;
   background: rgba(0, 0, 0, .05);
   margin: $small-gap 0 0;
   box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5) inset;
   font-size: 13px;
   font-style: italic;

   &.danger {
      background: $main-danger-color-light;
   }

   &.info {
      background: $main-informational-color-light;
   }

   &.take-note {
      background: $main-error-color-light;
   }

   &.success {
      background: $main-success-color-light;
   }

   &.addon-list {
      margin: 0 0 $medium-gap;
      padding-left: $large-gap;
      width: 100%;

      & > li {
         font-style: italic;
         padding-bottom: $small-gap;
         display: list-item;

         &:last-of-type {
            padding-bottom: 0;
         }
      }
   }
}

.notice {
   font-size: 12px;
   color: $gray-color;
   display: block;
   margin-bottom: $small-gap;
   font-style: italic;
}

.loading-input {
   background: var(--input-background-color);
   height: 44px;
   box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.15);
   border: 2px transparent solid;
   border-radius: 4px;
   cursor: pointer;
}

.table-thumbnail-image {
   height: 80px;
   width: 140px;
   display: inline-block;

   img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
   }
}

.flag-icon {
   width: 16px;
   height: 11px;
   display: inline-block;
   margin-left: $micro-gap;
   background-repeat: no-repeat;

   &.reverse {
      margin: 0 $micro-gap 0 0;
   }
}

.form-field, .grouped-elements, #main-single-crud-content {
   margin-bottom: $large-gap;
}

.form-field {
   @media screen and (max-width: $breakpoint-mobile) {
      margin-bottom: $medium-gap;
   }
}

.description {
   display: block;
   font-size: $smaller-font-size;
   color: $onwhite-faded-color;
   font-weight: 300;
   margin-top: 5px;
}

@include keyframes(classicFadeIn) {
   0% {opacity: 0;}
   100% {opacity: 1;}
}

.error-msg {
   background: #8a0000;
   color:#fff;
   border-radius: 4px;
   border-top-left-radius: 0px;
   border-top-right-radius: 0px;
   margin-top: -2px;
   font-size: 13px;
   display: inline-block;
   padding: 5px 10px;
   font-style: italic;
}

.right-align {
   text-align: right;
}

.right-float {
   float: right;
}

.label-button {
   &.classic-button {
      padding: 0;

      label span {
         display: block;
         padding: 10px 20px;
         cursor: pointer;
      }
   }
}

.small-padding {
   padding: $small-gap;
}

.medium-padding {
   padding: $medium-gap;
}

.building-backdrop {
   opacity: .2;
   position: relative;
   margin: auto;
   width: 744px;
   height: 257px;
   padding-top: 70px;
   overflow: hidden;
   color: #0e273f;

   i {
      position: absolute;

      &:nth-of-type(1) {
         font-size: 200px;
         margin-left: -13px;
      }
      &:nth-of-type(2) {
         font-size: 250px;
         margin-left: 119px;
         margin-top: -47px;
      }
      &:nth-of-type(3) {
         font-size: 150px;
         margin-left: 303px;
         margin-top: 47px;
      }
      &:nth-of-type(4) {
         font-size: 275px;
         margin-left: 399px;
         margin-top: -70px;
      }
      &:nth-of-type(5) {
         font-size: 175px;
         margin-left: 601px;
         margin-top: 23px;
      }
   }
}
