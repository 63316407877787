































@import '~@/variables';
@import '~@/functions';

#theme-changer {
   button {
      @include square(34px);
      padding: $small-gap;

      &:focus {
         @include input-outline();
      }

      &:hover {
         background: var(--header-hover-color);
      }
   }

   i {
      @include square(15px);
      color: var(--header-font-color);
      text-align: left;
      font-size: 16px;
   }
}
