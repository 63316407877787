





























































@import '~@/variables';
@import '~@/functions';

#locale-changer {
   height: 35px;
}
