@font-face {
   font-family: 'Montserrat';
   font-weight: 300;
   font-style: normal;
   src: url("../fonts/Montserrat-Light.otf") format("opentype");
}
@font-face {
   font-family: 'Montserrat';
   font-weight: 300;
   font-style: italic;
   src: url("../fonts/Montserrat-LightItalic.otf") format("opentype");
}
@font-face {
   font-family: 'Montserrat';
   font-weight: 400;
   font-style: normal;
   src: url("../fonts/Montserrat-Regular.otf") format("opentype");
}
@font-face {
   font-family: 'Montserrat';
   font-weight: 400;
   font-style: italic;
   src: url("../fonts/Montserrat-Italic.otf") format("opentype");
}
@font-face {
   font-family: 'Montserrat';
   font-weight: 700;
   font-style: normal;
   src: url("../fonts/Montserrat-SemiBold.otf") format("opentype");
}
@font-face {
   font-family: 'Montserrat';
   font-weight: 700;
   font-style: italic;
   src: url("../fonts/Montserrat-SemiBoldItalic.otf") format("opentype");
}
@font-face {
   font-family: 'Montserrat';
   font-weight: bold;
   font-style: normal;
   src: url("../fonts/Montserrat-Bold.otf") format("opentype");
}
@font-face {
   font-family: 'Montserrat';
   font-weight: bold;
   font-style: italic;
   src: url("../fonts/Montserrat-BoldItalic.otf") format("opentype");
}
