@mixin input-styling {
   @include rounded($main-button-rounding);
   @include smooth-ease(.25s);
   text-overflow: ellipsis;
   font-family: $main-font-family;
   background: var(--input-background-color);
   border: 2px transparent solid;
   padding: 10px 12px;
   width: 100%;
   line-height: 20px;
   font-size: 15px;
   font-weight: 400;
   height: $input-height;
   color: var(--font-color);
   
   &:focus {
      @include input-outline();
   }
}
