
















































































































































































































































































































































































































































































@import '~@/variables';
@import '~@/functions';

$mobile-logo-width: calc(#{$logo-collapsed-width} + #{$small-gap} + #{$small-gap});

@media screen and (max-width: $breakpoint-laptop) {
   #true-market-value-menu-item {
      display: none;
   }
}

header {
   position: fixed;
   z-index: 100000;
   top: 0;
   left: 0;
   right: 0;
   @include smooth-ease();

   &.collapsed, &.collapsing {
      top: -$header-topbar-height;

      #main-header {
         > div {
            grid-template-columns: $logo-collapsed-width 1fr;

            #logo-wrapper {
               a {
                  top: 0;

                  .prop-logo {
                     height: $logo-collapsed-height;
                     width: $logo-collapsed-width;
                  }
               }
            }
         }
      }
   }

   &.expanding {
      top: 0;
   }

   .header-std-layout {
      display: grid;
      grid-template-columns: $logo-width 1fr;
      grid-column-gap: $medium-gap;
      @include transition(grid-template-columns .25s ease-in-out, width .25s ease-in-out .125s);

      @media screen and (max-width: $breakpoint-small-mobile) {
         grid-template-columns: 1fr;
      }
   }

   #topbar-navigation {
      background: var(--background-accent-color-alt);
      height: $header-topbar-height;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      #topbar-navigation-links {
         float: left;

         #topbar-auth-user {
            display: inline-block;
            padding: $small-gap 12px;
            font-size: $smaller-font-size;
            font-style: italic;

            @media screen and (max-width: $breakpoint-pablet) {
               display: none;
            }
         }

         ul {
            font-size: 0;

            li {
               @include smooth-ease();

               &:hover {
                  background: var(--header-hover-color);
               }

               a {
                  font-size: $smaller-font-size;
                  padding: 10px 12px;
                  line-height: calc(#{$header-topbar-height} - 1px);

                  @media screen and (max-width: $breakpoint-small-mobile) {
                     padding: $small-gap;
                  }
               }
            }
         }

         a:focus {
            @include input-outline();
         }
      }

      #topbar-user-preferences {
         float: right;
         font-size: 0;

         &> div {
            float: left;
         }

         button {
            font-size: $smaller-font-size;
            text-decoration: underline;
            color: var(--link-color);

            &:hover {
               background: var(--header-hover-color);
            }
         }
      }
   }

   #main-header {
      background: var(--background-accent-color);
      box-shadow: var(--header-box-shadow);
      height: $header-main-height;
      padding: $small-gap 0;

      .header-std-layout {
         @media screen and (max-width: $breakpoint-small-mobile) {
            grid-template-columns: $mobile-logo-width 1fr;
            grid-column-gap: $small-gap;
         }
      }

      > div {
         height: $header-main-inner-height;

         #logo-wrapper {
            a {
               position: absolute;
               top: -$header-topbar-height;
               @include smooth-ease();

               @media screen and (max-width: $breakpoint-small-mobile) {
                  position: initial;
                  top: unset;
               }
            }
         }

         #navigation-wrapper {
            @include smooth-ease();

            #mobile-navigation-scene {
               .mobile.overlay {
                  position: fixed;
                  @include transition(opacity .25s ease-in-out);
               }

               .mobile.close {
                  z-index: 100;
                  position: fixed;
                  color: $white-color;
                  top: $medium-gap;
                  right: $medium-gap;

                  svg {
                     font-size: 32px;
                     width: 32px;
                  }
               }
            }

            #mobile-navigation-toggle {
               display: none;
               grid-template-columns: 25px 1fr;
               grid-column-gap: $micro-gap;
               padding: $small-gap;
               color: var(--font-color);
               @include rounded($small-gap);
               @include smooth-ease();
               margin: $small-gap 0;
               text-decoration: underline;

               &:hover {
                  background: var(--background-accent-color-alt);
               }

               i.fa-icon {
                  font-size: 22px;
               }

               b {
                  display: inline-block;
               }

               @media screen and (max-width: $breakpoint-pablet) {
                  display: grid;
               }
            }

            #primary-navigation {
               @media screen and (max-width: $breakpoint-pablet) {
                  display: block;
                  background: var(--background-accent-color-alt);
                  width: 100%;
                  box-shadow: 2px 2px 0 #d9d9d9;
                  border-radius: 4px;

                  &:before {
                     width: 0;
                     height: 0;
                     border: 5px solid transparent;
                     border-color: var(--input-background-color-alt) transparent transparent transparent;
                     transform: rotate(180deg);
                     margin-top: -10px;
                     margin-left: 8px;
                  }
               }

               @media screen and (max-width: $breakpoint-small-mobile) {
                  position: fixed;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  border-radius: 0;
                  box-shadow: unset;

                  &>ul li .header-submenu {
                     max-width: unset;
                     min-width: unset;
                  }
               }

               &.hide-mobile {
                  @media screen and (max-width: $breakpoint-pablet) {
                     display: none;
                  }
               }

               &>ul {
                  li {
                     padding: $small-gap 0;
                     margin-right: $medium-gap;

                     @media screen and (max-width: $breakpoint-pablet) {
                        margin: 0;
                        display: block;
                        padding: $small-gap;
                     }

                     .header-submenu {
                        position: absolute;
                        background: var(--background-accent-color-alt);
                        max-width: 230px;
                        min-width: 160px;
                        box-shadow: 2px 2px 0 #d9d9d9;
                        @include rounded($main-button-rounding);
                        margin-top: $small-gap;

                        @media screen and (max-width: $breakpoint-pablet) {
                           position: initial;
                           box-shadow: unset;
                           margin: 0;
                           min-width: initial;
                           @include rounded(initial);
                        }

                        &:before {
                           width: 0;
                           height: 0;
                           border: 5px solid transparent;
                           border-color: var(--input-background-color-alt) transparent transparent transparent;
                           transform: rotate(180deg);
                           margin-top: -10px;
                           margin-left: 8px;

                           @media screen and (max-width: $breakpoint-pablet) {
                              display: none;
                           }
                        }

                        &>li {
                           padding: 8px;
                           width: 100%;
                           display: block;

                           a {
                              grid-template-columns: 1fr;
                              color: var(--link-color);
                              @include rounded(unset);

                              &:hover {
                                 background: rgba(0, 0, 0, 0.05);
                              }

                              &:first-of-type {
                                 @include rounded($small-gap, $small-gap, null, null);
                              }

                              &:last-of-type {
                                 @include rounded(null, null, $small-gap, $small-gap);
                              }

                              span {
                                 @include ellipsis();
                              }
                           }
                        }
                     }

                     &>a {
                        display: grid;
                        grid-template-columns: 25px 1fr;
                        grid-column-gap: $micro-gap;
                        padding: $small-gap;
                        color: var(--font-color);
                        @include rounded($small-gap);
                        @include smooth-ease();

                        &[rel="noreferrer noopener"]:before {
                           color: var(--header-font-color);
                           right: 1px;
                           top: 1px;
                           font-size: 10px;
                        }

                        &:hover, &:focus {
                           background: var(--background-accent-color-alt);
                        }

                        &:focus {
                           @include input-outline();
                        }

                        i {
                           font-size: 22px;
                        }

                        b {
                           display: inline-block;
                        }
                     }

                     &:last-of-type {
                        margin: 0;
                     }
                  }
               }
            }
         }
      }
   }
}
